<template>
  <div>
    <asom-modal
      title="Create Discrepancy Report"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-alert
          class="col-span-2"
          v-if="error || submissionError"
          variant="error"
          :error-message="error || submissionError"
        />
        <asom-form-field
          label="Nature of Discrepancy"
          required
          :state="inputStates('formData.natureOfDiscrepancy')"
          error="Nature Of Discrepancy required"
        >
          <asom-input-select
            :objectModelValue="false"
            :state="inputStates('formData.natureOfDiscrepancy')"
            v-model="formData.natureOfDiscrepancy"
            :options="discrepancyOptions"
          />
        </asom-form-field>
        <div></div>
        <div v-if="formData.natureOfDiscrepancy == null"></div>
        <asom-form-field
          v-else-if="formData.natureOfDiscrepancy == otherTypeValue"
          label="Other Reason"
          required
          :state="inputStates('formData.otherReason')"
          error="Other Reason is required"
        >
          <asom-input-text
            type="text"
            v-model="formData.otherReason"
            :state="inputStates('formData.otherReason')"
          />
        </asom-form-field>
        <asom-form-field
          v-else
          label="Transaction No"
          required
          :state="inputStates('formData.transactionNo')"
          error="Transaction number is required"
        >
          <asom-input-text
            type="text"
            v-model="formData.transactionNo"
            :state="inputStates('formData.transactionNo')"
          />
        </asom-form-field>
        <div></div>
        <asom-form-field
          v-if="formData.natureOfDiscrepancy != null && formData.natureOfDiscrepancy != otherTypeValue"
          label="Available Transactions"
          class="col-span-2"
        >
          <asom-client-table
            v-if="stationTransactions.length > 0"
            :columns="[ 'no', 'transactionNo', 'dateCreated', 'action' ]"
            :data="stationTransactions"
            :pagination="true"
            :filterable="true"
          >
            <template v-slot:no="slotScoped">
              {{slotScoped.rowIndex + 1}}
            </template>
            <template v-slot:dateCreated="scopedSlots">
              <span>{{ displayUtcDate(scopedSlots.data) }}</span>
            </template>
            <template v-slot:action="scopedSlots">
              <asom-button size="sm" text="Select" @click="selectTransaction(scopedSlots.rowData)" />
            </template>
          </asom-client-table>
          <p v-else class="text-semibold">No transaction found</p>
        </asom-form-field>
        <asom-form-field
          class="row-span-2"
          label="Description of Discrepancy"
          required
          :state="inputStates('formData.description')"
          error="Description is required"
        >
          <asom-input-textarea
            rows="4"
            v-model="formData.description"
            placeholder="Add Description"
            :state="inputStates('formData.description')"
          />
        </asom-form-field>
        <asom-form-field
          class="row-span-2"
          label="Parties Involved"
          required
          :state="inputStates('formData.involvedParties')"
          error="Parties Involved is required"
        >
          <asom-input-textarea
            rows="4"
            v-model="formData.involvedParties"
            placeholder="Add Involved Parties"
            :state="inputStates('formData.involvedParties')"
          />
        </asom-form-field>
        <asom-form-field
          class="row-span-2"
          label="Action Taken"
          required
          :state="inputStates('formData.actionTaken')"
          error="Action Taken is required"
        >
          <asom-input-textarea
            rows="4"
            v-model="formData.actionTaken"
            placeholder="Add Action Taken"
            :state="inputStates('formData.actionTaken')"
          />
        </asom-form-field>
        <asom-form-field
          class="col-span-2"
          label="Supporting documents"
          :state="inputStates('formData.files')"
          error="Supporting documents are required"
        >
          <asom-upload
            v-model="formData.files"
            :category="attachmentCategories.CASH_MANAGEMENT"
            :state="inputStates('formData.files')"
          />
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Back"
            variant="secondary"
            @click="onBack"
          />
          <asom-button
            text="Submit"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </div>
      </form>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import { discrepancyNature } from "@/constants/APIEnums/cashManagementEnums";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  name: "CreateManualRecord",
  mixins: [inputStates],
  props: {
    cashAccountId: {
      type: String,
    },
    isSubmitting: {
      type: Boolean,
    },
    submissionError: {
      type: undefined,
    },
    discrepancyOptions: {
      type: Array,
      default: () => [],
    }
  },
  emits: [ 'submit', 'back', 'storeOffline' ],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      formData: {
        natureOfDiscrepancy: null,
        transactionNo: null,
        otherReason: null,
        description: null,
        involvedParties: null,
        actionTaken: null,
        files: [],
      },
      otherTypeValue: discrepancyNature.OTHERS.VALUE,
      inputValidation: false,
      isLoading: false,
      error: null,
      showModal: false,
    };
  },
  validations() {
    let validations = {
      formData: {
        natureOfDiscrepancy: {
          required,
        },
        description: {
          required,
        },
        involvedParties: {
          required,
        },
        actionTaken: {
          required,
        },
      },
    };
    if (this.formData.natureOfDiscrepancy === this.otherTypeValue) {
      validations.formData["otherReason"] = {
        required,
      };
    } else {
      validations.formData["transactionNo"] = {
        required,
      };
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      isOnline: "apiStatus/isCMModuleOnline",
    }),
    submissionData() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      return {
        natureOfDiscrepancy: this.formData.natureOfDiscrepancy,
        accountId: this.cashAccountId,
        transactionNo: this.formData.transactionNo,
        otherReason: this.formData.otherReason,
        description: this.formData.description,
        involvedParties: this.formData.involvedParties,
        actionTaken: this.formData.actionTaken,
        reportedById: this.userId,
        attachmentIds: uploadedIds,
      };
    },
    stationTransactions() {
      if (!this.formData.natureOfDiscrepancy)
        return this.$store.state.cashManagement.stationTransactions.list;
      else
        return this.$store.getters['cashManagement/stationTransactions/transactionsOfTypes'](this.formData.natureOfDiscrepancy);
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    displayUtcDate,
    setTransactions(selectedTransaction) {
      this.formData.transactionNo = get(
        selectedTransaction,
        "transactionNo",
        null
      );
    },
    selectTransaction({ transactionNo }) {
      this.formData.transactionNo = transactionNo;
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    onSubmit() {
      if (this.isOnline) {
        this.$emit('submit', this.submissionData);
      } else {
        this.showModal = false;
        this.$emit('storeOffline', this.submissionData);
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
    onBack() {
      this.$emit('back')
    }
  },
};
</script>
